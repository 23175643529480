import { createI18n } from "vue-i18n";

export const i18n = createI18n({
  legacy: false,
  locale: "ko",
  messages: {
    ko: {
      confirm: "확인",
      cancel: "취소",
    },
    en: {
      confirm: "Confirm",
      cancel: "Cancel",
    },
    zh: {},
    ja: {},
  },
});
