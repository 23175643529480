import { defineStore } from "pinia";

export const useUserDataStore = defineStore("userData", {
  state: () => ({
    user: {
      id: 0,
      telegram_id: 0,
      user_name: "",
      first_name: "",
      last_name: "",
      point: '0',
      language_code: "",
      is_active: false,
      referral_user_name: "",
      referral: null,
      referral_code: "",
      energy: 0,
      attendance : 0,
      last_login: "",
      chain : null,
    },
  }),
  getters: {},
  actions: {
    setUserData(parma) {
      const { id, telegram_id, user_name, first_name, last_name, point, language_code, is_active, referral } = parma;
      this.user.id = id;
      this.user.telegram_id = telegram_id;
      this.user.user_name = user_name;
      this.user.first_name = first_name;
      this.user.last_name = last_name;
      this.user.point = point;
      this.user.language_code = language_code;
      this.user.is_active = is_active;
      this.user.referral = referral;
    },
    increamentPoint() {
      this.user.point++
    },
    setPoint(point) {
      this.user.point = point;
    },
    setReferralUsername(name) {
      this.user.referral_user_name = name;
    },
    setReferralCode(code) {
      this.user.referral_code = code;
    },
    setEnergy(energy) {
      if (energy > 1000) {
        this.user.energy = 1000;
      } else {
        this.user.energy = energy;
      }      
    },
    setId(id) {
      this.user.id = id;
    },
    setAttendance(attendance) {
      this.user.attendance = attendance;
    },
    setLastLogin(date) {
      this.user.last_login = date;
    },
    setChain(chain) {
      this.user.chain = chain;
	}
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "userData",
        storage: localStorage,
      },
    ],
  },
});
