import { defineStore } from "pinia";

export const useCommonDataStore = defineStore("commonData", {
  state: () => ({
    mobile: false,
    route: {
      page: '',
    },
    api : {
      API_GET_USER_INFO : "https://api.aniwalk.io/api/v1/users/getUser",
      API_ADD_POINT : "https://api.aniwalk.io/api/v1/point/addPoint",
      API_SIGN_UP : "https://api.aniwalk.io/api/v1/users/signUp",
      API_GET_REFERRAL_INFO : "https://api.aniwalk.io/api/v1/users/getReferral",
      API_GET_INVITED_USER : "https://api.aniwalk.io/api/v1/users/getInvitedUser",
      API_SET_PROPERTY : "https://api.aniwalk.io/api/v1/setProperty",
      API_GET_PROPERTY : "https://api.aniwalk.io/api/v1/getProperty",
      API_ADD_EARN_POINT : "https://api.aniwalk.io/api/v1/point/addEarnPoint",
      API_GET_EARN_POINT : "https://api.aniwalk.io/api/v1/point/getEarnPointByType",
      API_RECIEVE_EARN_POINT : "https://api.aniwalk.io/api/v1/point/recieveEarnPoint",
      API_EARNED_POINT : "https://api.aniwalk.io/api/v1/point/earnedPointByType",
      API_ADD_EARN_POINT_ADND_RECEIVE : "https://api.aniwalk.io/api/v1/point/addEarnPointAndReceive",
      API_CHECK_ATTENDANCE : "https://api.aniwalk.io/api/v1/users/checkAttendance",
      API_GET_ENERGY_AND_POINT : "https://api.aniwalk.io/api/v1/point/getEnergyAndPoint",
      API_ALL_EARNED_POINT : "https://api.aniwalk.io/api/v1/point/allEarnedPoint",
      API_POINT_TOP_USERS : "https://api.aniwalk.io/api/v1/users/getUserTopPoint",
      API_GET_POINT_HISTORY : "https://api.aniwalk.io/api/v1/point/getPointHistory",
      API_GET_SELECTED_CHAIN : "https://api.aniwalk.io/api/v1/users/getSelectedChain",
      API_SELECTED_CHAIN : "https://api.aniwalk.io/api/v1/users/selecteChain",
 
      API_ADD_DOMESTIC_ISSUE : "https://api.aniwalk.io/api/v1/issue/addDomesticIssue",
      API_GET_DOMESTIC_ISSUE : "https://api.aniwalk.io/api/v1/issue/getDomesticIssue",
      API_VOTE_DOMESTIC_ISSUE : "https://api.aniwalk.io/api/v1/issue/voteDomesticIssue",
      API_GET_DOMESTIC_ISSUE_RESULT : "https://api.aniwalk.io/api/v1/issue/getDomesticIssueResult",
      
      API_ADD_GLOBAL_ISSUE : "https://api.aniwalk.io/api/v1/issue/addGlobalIssue",
      API_GET_GLOBAL_ISSUE : "https://api.aniwalk.io/api/v1/issue/getGlobalIssue",
      API_VOTE_GLOBAL_ISSUE : "https://api.aniwalk.io/api/v1/issue/voteGlobalIssue",
      API_GET_GLOBAL_ISSUE_RESULT : "https://api.aniwalk.io/api/v1/issue/getGlobalIssueResult",
    },
  }),
  getters: {},
  actions: {
    updateRoute(route) {
      this.route.page = route;
    }
  },
});
