<template>
  <router-view />
</template>

<script>
import { useUserDataStore } from '@/store/user_data'
import { useCommonDataStore } from './store/common_data'

export default {
  name: 'App',
  data() {
    return {
      platform: '',
      colorScheme: '',
      themeBgColor: '',
      isMobile: false,
    };
  },
  mounted() {
    const userDataStore = useUserDataStore();
    const commonDataStore = useCommonDataStore();

    this.isMobile = /Mobi|Android/i.test(navigator.userAgent);
    
    commonDataStore.mobile = this.isMobile;
  
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

      userDataStore.setUserData({
        id: initDataUnsafe.user.id || 0,
        telegram_id: initDataUnsafe.user.id || 0,
        user_name: initDataUnsafe.user.username || 'default_user',
        first_name: initDataUnsafe.user.first_name || 'Default',
        last_name: initDataUnsafe.user.last_name || 'User',
        point: 0,
        language_code: initDataUnsafe.user.language_code || 'en',
        is_active: true,
        referral: initDataUnsafe.start_param,
      });

      this.platform = window.Telegram.WebApp.platform || '';
      this.colorScheme = window.Telegram.WebApp.colorScheme || '';
      this.themeBgColor = window.Telegram.WebApp.themeParams.bg_color || '#ffffff';

      window.Telegram.WebApp.onEvent('themeChanged', () => {
        this.colorScheme = window.Telegram.WebApp.colorScheme;
        this.themeBgColor = window.Telegram.WebApp.themeParams.bg_color || '#000';
      });

      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.setHeaderColor('#000000');
      window.Telegram.WebApp.setBackgroundColor('#000000');
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
    } else {
      // Telegram이 아닌 경우 기본값 설정
      userDataStore.setUserData({
        id: 21,
        telegram_id: 977100088,
        // id: 41,
        // telegram_id: 441416423,
        // id: 41,
        // telegram_id: 441416423,
        user_name: '1234',
        first_name: 'LEE',
        last_name: 'DONGDONG',
        point: 0,
        language_code: 'en',
        is_active: true,
      });
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    expandApp() {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.expand();
      }
    },
    sendDataToBot() {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.sendData('Hello, bot!');
      }
    },
    showAlert(message) {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.showAlert(message);
      }
    },
    readClipboard() {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.readTextFromClipboard((text) => {
        });
      }
    },
    changeTheme() {
      if (window.Telegram && window.Telegram.WebApp) {
        this.isDarkTheme = !this.isDarkTheme;
        const headerColor = this.isDarkTheme ? '#000000' : '#000';
        const backgroundColor = this.isDarkTheme ? '#1c1c1c' : '#000';

        window.Telegram.WebApp.setHeaderColor(headerColor);
        window.Telegram.WebApp.setBackgroundColor(backgroundColor);
        this.themeBgColor = backgroundColor;
      }
    }
  },
};
</script>

<style></style>
